<template>
<ComponentsLayoutPage1>
  <v-row align="center" justify="center" no-gutters>
    <v-col cols="12" v-show="News.length > 0">
      <v-row align="center" justify="center" no-gutters>
        <v-col cols="12" v-for="item of News" :key="item.news_id">
          <v-alert
            dense
            outlined
            prominent
            border="left"
            type="warning"
          >
            <span style="word-break: break-all; white-space: pre-line;" v-text="item.news.contents"></span>
          </v-alert>
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="12">
      <ComponentsViewFormsMainBanner
      >
      </ComponentsViewFormsMainBanner>
    </v-col>

    <v-col cols=12 class="text-center pa-5">
      <span :class="(windowMiddleSize) ? 'caption font-weight-bold' : 'font-weight-bold'">
        犬猫の幸せのために、あらゆるサービスで、食を軸とした提案力アップをコンサルティングサポートします<br />
        卸専用ショップR2(アール・ツー)
      </span>
    </v-col>

    <v-col cols=12 class="px-3 pt-5">
      <ComponentsItemsBanner
        :imgSrc="sample1Image"
        :alt="'キャンペーン'"
        :route="'/campaign'"
        contain
      >
      </ComponentsItemsBanner>
    </v-col>

    <v-col cols=12 class="px-3 pt-5">
      <v-row align="center" justify="start">
        <v-col :cols="(windowSpSize) ? 6 : 4" class="d-flex child-flex" v-for="banner in banners" :key="banner.src">
          <ComponentsItemsBanner
            :imgSrc="banner.imgSrc"
            :alt="banner.alt"
            :route="banner.page"
            :width="(windowSpSize) ? 420 : 315"
            :height="(windowSpSize) ? 140 : 103"
            contain
          >
          </ComponentsItemsBanner>
        </v-col>
      </v-row>
    </v-col>

    <v-col cols=12 class="px-3 pt-5">
      <ComponentsViewFormsNewRelease
      >
      </ComponentsViewFormsNewRelease>
    </v-col>

    <v-col cols="12" class="px-3 pt-5">
      <ComponentsViewFormsInformation
      >
      </ComponentsViewFormsInformation>
    </v-col>

    <v-col cols=12 class="px-3 pt-5">
      <ComponentsViewFormsIntroduce
      >
      </ComponentsViewFormsIntroduce>
    </v-col>

  <!--
    <v-col cols=12 md=6 lg=6 xl=6 class="pa-1">
      <ComponentsBlockIntroduceBrand></ComponentsBlockIntroduceBrand>
    </v-col>

    <v-col cols=12 md=6 lg=6 xl=6 class="pa-1">
      <ComponentsBlockIntroduceCategory></ComponentsBlockIntroduceCategory>
    </v-col>

    <v-col cols="12" class="px-3 pt-5">
      <ComponentsViewFormsRecommend
      >
      </ComponentsViewFormsRecommend>
    </v-col>
  -->

    <v-col cols="12" class="px-3 pt-5">
      <ComponentsViewFormsSalesRanking
      >
      </ComponentsViewFormsSalesRanking>
    </v-col>

  </v-row>
</ComponentsLayoutPage1>
</template>
<script>
import MixinLib from '@/libs/MixinLib'

import ComponentsLayoutPage1 from '@/components/layout/Page1'

import ComponentsViewFormsMainBanner from '@/components/view-forms/MainBanner'
import ComponentsViewFormsInformation from '@/components/view-forms/Information'
import ComponentsViewFormsNewRelease from '@/components/view-forms/NewRelease'
import ComponentsViewFormsIntroduce from '@/components/view-forms/Introduce'
// import ComponentsViewFormsRecommend from '@/components/view-forms/Recommend'
import ComponentsViewFormsSalesRanking from '@/components/view-forms/SalesRanking'
import ComponentsItemsBanner from '@/components/items/Banner'

// import ComponentsBlockIntroduceBrand from '@/components/BlockIntroduceBrand'
// import ComponentsBlockIntroduceCategory from '@/components/BlockIntroduceCategory'

export default {
  watch: {
  },
  name: 'TopPage',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: true,
    sample1Image: require('@/assets/banner/sample5.jpg'),
    sample2Image: require('@/assets/banner/sample4.jpg'),
    banners: [
      /*
      {
        page: '/campaign',
        imgSrc: require('@/assets/banner/bnr_campaign.jpg'),
        alt: 'キャンペーン'
      },
      {
        page: '/limited-products-list',
        imgSrc: '',
        alt: 'R2限定商品'
      },
      */
      {
        page: '/entry',
        imgSrc: require('@/assets/banner/apply.jpg'),
        alt: 'お取引をご希望の方'
      },
      {
        page: '/contact',
        imgSrc: require('@/assets/banner/contact.jpg'),
        alt: 'お問合せ'
      },
      {
        page: '/guide',
        imgSrc: require('@/assets/banner/bnr-guide.jpg'),
        alt: 'ご利用ガイド'
      }
    ]
  }),

  computed: {
    News: {
      get () {
        return this.$store.getters['modStorage/News']
      }
    }
  },

  mounted () {
  },

  components: {
    ComponentsLayoutPage1,
    ComponentsViewFormsMainBanner,
    ComponentsViewFormsInformation,
    // ComponentsViewFormsRecommend,
    ComponentsViewFormsSalesRanking,
    ComponentsViewFormsNewRelease,
    ComponentsViewFormsIntroduce,
    ComponentsItemsBanner
    // ComponentsBlockIntroduceBrand,
    // ComponentsBlockIntroduceCategory,
  }
}
</script>
