<template>
<div v-if="maintenance">
  <v-app :style="(windowMiddleSize) ? 'background-color:rgba(var(--b3f,250,250,250),1);' : 'background-color: white;'" >
    <v-main>
      <v-container fill-height>
        <v-card flat class="mx-auto mt-5">
          <v-card-text>
            <v-row align="center" justify="center">
              <v-col cols=12 class="text-center">
                <span class="font-weight-bold ec-font-size20 red--text">ただいまメンテナンス中です</span>
              </v-col>
              <v-col cols=12 class="text-center">
                <ComponentsLayoutSection1>
                  <v-row align="center" justify="center">
                    <v-col cols=12 class="text-center">
                      <span>株式会社アールツー通販サイトをご利用いただきまして、誠にありがとうございます。</span><br />
                      <span>現在システムメンテナンスを行っております。</span><br />
                      <span>ご不便をおかけいたしますが、メンテナンス終了までしばらくお待ちください</span>
                    </v-col>
                  </v-row>
                </ComponentsLayoutSection1>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-container>
    </v-main>
    <v-footer absolute app color="R2FooterColor" padless height="40">
      <v-row no-gutters>
        <v-col cols="12" class="py-2 text-center">
          <span class="'mx-auto ec-font-size10" >copyright (c) R2(アール・ツー) all rights reserved.</span>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</div>
<div v-else>
  <div v-if="isLoading">
    <transition name="fade">
      <ComponentsLayoutLoadingScreen></ComponentsLayoutLoadingScreen>
    </transition>
    <transition name="fade">
      <router-view transition="fade-transition" v-if="!isLoading" />
    </transition>
  </div>

  <router-view v-if="!isLoading" />

  <vue-confirm-dialog></vue-confirm-dialog>

  <v-dialog
    v-model="versionUp"
    persistent
    max-width="290"
  >
    <v-card>
      <v-card-text>
        <v-row no-gutters>
          <v-col cols=12 class="pt-5 text-center">
            <span class="ec-font-size16 blue-grey--text text--lighten-1">UPDATE</span>
          </v-col>
          <v-col cols=12>
            <v-progress-linear
              v-model="progress"
            ></v-progress-linear>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</div>
</template>
<script>
import MixinLib from '@/libs/MixinLib'
import AuthLib from '@/libs/AuthLib'
import axios from 'axios'

import ComponentsLayoutSection1 from '@/components/layout/Section1'
import ComponentsLayoutLoadingScreen from '@/components/layout/LoadingScreen'

export default {
  name: 'App',

  mixins: [MixinLib],

  data: () => ({
    versionUp: false,
    interval: 0,
    progress: 0,
    // showFloatingAction: false,
    storageDataRefreshInterval: '',
    storageDataRefreshTime: 1800000, // 30分
    checkSessionInterval: '',
    checkSessionTime: 600000 // 10分
  }),

  computed: {
    maintenance: {
      get () {
        console.log(this.$store.getters['modStorage/MaintenanceMode'])
        return this.$store.getters['modStorage/MaintenanceMode']
      }
    }
  },

  watch: {
    '$route' (routeInstance) {
      this.createTitleDesc(routeInstance)
    }
  },

  created: function () {
    this.$store.dispatch('modPage/reset')
    // const accessToken = Cookies.get(process.env.VUE_APP_TOKEN_COOKIE_NAME)
    // if (!UtilLib.isUndefined(accessToken)) {
    //   this.$store.dispatch('modSession/setAccessToken', accessToken)
    // }
    this.refreshWindowSize()
    window.addEventListener('resize', this.refreshWindowSize)
    window.addEventListener('scroll', this.refreshWindowSize)
    window.addEventListener('load', this.refreshWindowSize)
  },

  mounted: function () {
    (async () => {
      await this.checkVersion()
      this.createTitleDesc(this.$route)
      window.Multipayment.init(process.env.VUE_APP_CREDIT_APIKEY)
      await AuthLib.Configure()
      await AuthLib.onListen(this.SignInSuccess, this.SignInError)
      const status = await AuthLib.isLogin()
      if (status) {
        if (this.isInitialLoading === false) {
          this.isInitialLoading = true
          const uri = (this.routeURI.endsWith('/')) ? this.routeURI.slice(0, -1) : this.routeURI
          await this.SignInLoading((this.routeURI === '/') ? '/' : uri)
        }
      } else {
        await this.SignOut()
      }
      this.goInitLoading()
    })().catch((e) => {
      this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
    })
  },

  methods: {
    checkVersion: async function () {
      axios({
        method: 'GET',
        url: '/version.json?' + parseInt(new Date() / 1000),
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(response => {
        if (process.env.VUE_APP_VERSION !== response.data.version) {
          this.$confirm({ title: 'Confirm', message: '最新のバージョンにアップデートを行います', button: { yes: 'OK' }, callback: this.updateVersion })
        }
      })
    },
    updateVersion: async function () {
      this.versionUp = true
      window.navigator.serviceWorker.getRegistrations()
        .then(registrations => {
          for (const registration of registrations) {
            registration.unregister()
          }
        })
      setTimeout(() => {
        this.interval = setInterval(() => {
          if (this.progress === 100) {
            clearInterval(this.interval)
            return window.location.reload(true)
          }
          this.progress += 20
        }, 1000)
      }, 5000)
    },
    updateInitialize: async function () {
      console.log('updateinit')
      window.navigator.serviceWorker.getRegistrations()
        .then(registrations => {
          for (const registration of registrations) {
            registration.unregister()
          }
        })
    },
    refreshWindowSize: function () {
      if (this.windowWidthSize !== window.innerWidth) {
        this.windowWidthSize = window.innerWidth
      }
      if (this.windowHeightSize !== window.innerHeight) {
        this.windowHeightSize = window.innerHeight
      }
      // this.showFloatingAction = (window.pageYOffset >= 32)
    },

    createTitleDesc: function (routeInstance) {
      if (routeInstance.meta.title) {
        document.title = routeInstance.meta.title
      }
      if (routeInstance.meta.desc) {
        document.querySelector("meta[name='description']").setAttribute('content', routeInstance.meta.desc)
      }
    },

    // 初期処理
    goInitLoading: function () {
      (async () => {
        // バックグラウンド処理の実行
        this.storageDataRefresh()
        this.storageDataRefreshInterval = setInterval(this.storageDataRefresh, this.storageDataRefreshTime)
      })().catch((e) => {
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
        this.destroySession()
      })
    },

    // バックエンドデータ更新
    storageDataRefresh: function () {
      (async () => {
        this.$store.dispatch('modStorage/LoadMaster')
      })().catch((e) => {
        this.$confirm({ title: 'Error', message: '一部コンテンツの読み込みに失敗しました', button: { yes: 'OK' } })
      })
    },

    // セッション削除
    destroySession: function () {
      (async () => {
        clearInterval(this.storageDataRefreshInterval)
      })().catch((e) => {
      })
    },

    beforeDestroy: function () {
      this.destroySession()
    }
  },

  components: {
    ComponentsLayoutSection1,
    ComponentsLayoutLoadingScreen
  }

}
</script>
<style>
.right-input-select .v-select__selections {
  direction: rtl;
}

.right-input input {
  text-align: right;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

.v-application .caption {
  font-family: 'Helvetica Neue', Tabular,'Hiragino Kaku Gothic ProN', 'Hiragino Sans', 'BIZ UDPGothic', Meiryo, sans-serif !important;
}

h1, h2, h3, h4, p, span, div, input, pre {
  font-family: 'Helvetica Neue', Tabular,'Hiragino Kaku Gothic ProN', 'Hiragino Sans', 'BIZ UDPGothic', Meiryo, sans-serif !important;
}

.text-omitted {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

a {
  text-decoration: none;
}

.ec-font-size8 {
  font-size: 10px!important;
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
  transform: scale(0.8);
}

.ec-font-size10 {
  font-size: 10px!important;
}

.ec-font-size12 {
  font-size: 12px!important;
}

.ec-font-size14 {
  font-size: 14px!important;
}

.ec-font-size16 {
  font-size: 16px!important;
}

.ec-font-size18 {
  font-size: 18px!important;
}

.ec-font-size20 {
  font-size: 20px!important;
}

.ec-font-size22 {
  font-size: 22px!important;
}

.ec-font-size32 {
  font-size: 32px!important;
}

.ec-font-size40 {
  font-size: 40px!important;
}

.ec-row-h25 {
  height: 25px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ec-row-h40 {
  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ec-row-h80 {
  height: 80px;
  overflow: hidden;
  text-overflow: clip;
}

.ec-secHeading {
  margin-bottom: 15px;
  color: black;
}

.ec-secHeading .ec-secHeading__en {
  font-size: clamp(14px, 1.2vw, 18px);
  font-weight: bold;
  letter-spacing: .2em;
}

.ec-secHeading .ec-secHeading__line {
  display: inline-block;
  margin: 0 20px;
  width: 1px;
  height: 14px;
  background: black;
}

.ec-secHeading .ec-secHeading__ja {
  font-size: clamp(6px, 1vw, 12px);
  font-weight: normal;
  letter-spacing: .15em;
  vertical-align: 2px;
}
</style>
